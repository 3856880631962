import { Component, OnInit  } from '@angular/core';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
import { CommonServiceService } from "../../../common-service.service";
import { URL } from "../../../model/url";
import { NbThemeService, NbToastrService } from '@nebular/theme';
import { ToasterConfig } from 'angular2-toaster';
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: 'ngx-balancechart',
  templateUrl: './balancechart.html',
  styleUrls: ['./balancechart.scss']
})

export class BalancechartComponent implements OnInit {

  userData: any;
  userList : any;
  AllBalance = [];

  token : any

  doughnutChartLabels: Label[] = [];
  doughnutChartData: MultiDataSet = [[]];
  doughnutChartType: ChartType = 'doughnut';
  private options: any = {
    legend: { position: 'bottom' },
    cutoutPercentage: 70,
    
  }

  constructor(  public cms: CommonServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: NbToastrService,) { 

    this.userData = this.cms.getuserData();


this.token = this.userData.PublicKey
    

    
    this.getAllBalances();
    
    this.doughnutChartData = [[25,25,25,25]]

      
  }

  
  ngOnInit() {
   
  }

  array1 = []


  AllBalances = []
  getAllBalances(){
    this.cms.commonGetCall(URL.getAllBalance).subscribe(
      (x: any) => {
        console.log("all balancessss in chat",x);
        // this.AllBalances = x.Data


        x.Data.forEach(res => {
          this.doughnutChartLabels.push(res.coin);
          this.array1.push(res.balance)
  
          console.log("this.array1",this.array1);
          
        });
        this.doughnutChartData = [this.array1]
       
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getCoinsList() {
    this.cms.commonGetCall(URL.getcoinlist)
      .subscribe((resp: any) => {
        if (resp.Status == 200) {
          console.log("all coins list   .....", resp);
          if (resp.Data && resp.Data.length != 0) {
            let data = resp.Data;
            this.getcoinIds(data);
          }
        }

      })
  }

  getcoinIds(data) {
    data.forEach(x => {
      this.getAllBalance(x);
    
    })
    console.log("the balances data", this.AllBalance);

  }

  getAllBalance(data) {
    this.cms.commonGetCallOtherToken(URL.getTotalBalance + "/" + data.coinId, this.token)
      .subscribe((resp: any) => {
        console.log("Balance", resp);
        if (resp.Status == 200) {
          if (resp.Data) {
            let obj = {
              coinName: data.coinName,
              icon: data.icon,
              CoinBalance: resp.Data.balance
            }
            this.doughnutChartLabels.push(data.coinName);
            
            this.array1.push(resp.Data.balance)

            console.log("this.array1",this.array1);
            

            this.doughnutChartData = [this.array1]
            this.AllBalance.push(obj)
          }

        }
        else {

        }
      })
  }


  getuserData2(){
    var payload = {
      CmID : this.userData.CmID
    }
    let url = "";
    url = URL.getChildData
    this.cms.commonPostCall(url, payload).subscribe((res: any) => {
      console.log("Users", res);
      if (res.Status == 200) {
        var count = []
        var newdata = []
         this.userList = res.Data;
        
         console.log("child data issssss...",this.userList);

        //  this.userList.forEach(x=>{
        //    this.doughnutChartLabels.push(x.ROLEName)
        //    count.push(x.count)
        //   //  this.doughnutChartData = [[x.count]] 
        //   //  this.doughnutChartData.push(x.count)
        //  })


          // newdata.push(count)
          this.doughnutChartLabels = ['BMW', 'Ford', 'Tesla'];
           this.doughnutChartData = [[2,22,2]]
        console.log("my data issssss...",this.doughnutChartData);
      }
      else {
        this.toastrService.warning(res.message, "Error")
      }

    });

  }

}
