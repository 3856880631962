import {Component, OnDestroy} from '@angular/core';
import { NbThemeService,NbToastrService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators' ;
import { SolarData } from '../../@core/data/solar';
import { URL} from '../../model/url';
import { CommonServiceService } from '../../common-service.service';
import { ToasterConfig } from 'angular2-toaster';
import 'style-loader!angular2-toaster/toaster.css';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'ngx-login',
  styleUrls: ['./grandadmin.component.scss'],
  templateUrl: './grandadmin.component.html',
})
export class GrandadminComponent implements OnDestroy {

    private alive = true;
    // user={
    //   email : '',
    //   password : ''
    // }

    navigateHome()
    {
      
    }
    config: ToasterConfig;
    check = false;

  constructor(private themeService: NbThemeService, public cms:CommonServiceService,
              private solarService: SolarData,
              private toastrService: NbToastrService,
              private router: Router,
              private route: ActivatedRoute
              ) {

                this.route.queryParams.subscribe(params => {
                  console.log("params",params);

                  if (params.id)
                  {
                    this.check = true;

                    var split  = atob(params.id).split(",,");
                    this.user = {
                      firstname: split[0],
                      lastname: split[1],
                      email: split[2],
                      companyname :split[3],
                      address: split[4],
                      roleid: ""
                    };
                   // console.log("params",split);
                  }
                });


                // this.toastrService.('error', 'Args Title');

              }

  ngOnDestroy() {
    this.alive = false;
  }
  error = [];
  user = {
    firstname: "",
    lastname: "",
    email: "",
    companyname :'',
    address: "",
    roleid: ""
  };

  AddUser() {

    this.error = [];
    var { error } = this;
    if(!this.user.firstname)
    {
      error[0] = "First Name is required "
    }
    if(!this.user.lastname)
    {
      error[1] = "Last Name is required "
    }

    if(!this.user.email)
    {
      error[2] = "Email is required "
    }
    if(!this.user.companyname)
    {
      error[3] = "Company Name is required "
    }
    if(!this.user.address)
    {
      error[4] = "Company Address is required "
    }
    if( this.error.length  != 0)
    {
      return null;
    }

let payload={
  FNAME: this.user.firstname,
  LNAME: this.user.lastname,
  EMAIL: this.user.email,
  COMPANYNAME: this.user.companyname,
  COMPANYADDRESS: this.user.address
}
    this.cms.simplePost(URL.GrandAminCreate,payload).subscribe((res:any)=>{
      if(res.Status == 200){
        this.toastrService.success(res.message,"Success")
       
      }
      else{
        this.toastrService.warning(res.message,"Error")
      }
    })
    
  }

  forgotPassword(){
    this.router.navigate(['/forgotpassword'])
  }

  Request()
  {
   // console.log("payload:",this.user);

   this.error = [];
   var { error } = this;
   if(!this.user.firstname)
   {
     error[0] = "First Name is required "
   }
   if(!this.user.lastname)
   {
     error[1] = "Last Name is required "
   }

   if(!this.user.email)
   {
     error[2] = "Email is required "
   }
   if(!this.user.companyname)
   {
     error[3] = "Company Name is required "
   }
   if(!this.user.address)
   {
     error[4] = "Company Address is required "
   }
   if( this.error.length  != 0)
   {
     return null;
   }

   var obj = {
     email:this.user.email,
     key:btoa(this.user.firstname+",,"+this.user.lastname+",,"+this.user.email+",,"+this.user.companyname+",,"+this.user.address),
     name:this.user.firstname+" "+this.user.lastname  
   }
  
   this.cms.simplePost(URL.REQUEST_NEW_ACCOUNT,obj).subscribe((res:any)=>{
    if(res.Status == 200){
      this.toastrService.success(res.message,"Success")
     
    }
    else{
      this.toastrService.warning(res.message,"Error")
    }
  })

   console.log("obj",obj)
  }
}
