import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core'
import {
  NbThemeService,
  NbToastrService,
  NbDialogService,
} from '@nebular/theme'
import { takeWhile } from 'rxjs/operators'
import { SolarData } from '../../@core/data/solar'
import { CommonServiceService } from '../../common-service.service'
import { URL } from '../../model/url'
import { ToasterConfig } from 'angular2-toaster'
import 'style-loader!angular2-toaster/toaster.css'
import { Router, ActivatedRoute } from '@angular/router'

interface CardSettings {
  title: string
  iconClass: string
  type: string
}

@Component({
  selector: 'ngx-transfer',
  styleUrls: ['./transfer.component.scss'],
  templateUrl: './transfer.component.html',
})
export class TransferComponent implements OnDestroy, OnInit {
  dollorvolume: number = 0
  fee: any
  transferamount: any

  DashMarketPrice: 0
  EthMarketPrice: 0
  BtcMarketPrice: 0
  VgwMarketPrice: 0

  payload = {
    coinId: null,
    volume: '',
    TransferAddress: '',
    Comment: '',
  }
  userData: any
  selectCoinData = {
    coinId: null,
    coinName: '',
    fullName: '',
    toolTip: '',
    isActive: false,
    environment: '',
    displayName: '',
    endPoint: '',
    icon: '',
    Price: 0,
  }
  CoinBalance = 0
  tempval = 0

  private alive = true

  role: any

  selectcoinName: ''
  secretCode: any
  qrCode: any
  coinVolume: number

  constructor(
    private toastrService: NbToastrService,
    private cms: CommonServiceService,
    private themeService: NbThemeService,
    private router: Router,
    private solarService: SolarData,
    private dialogService: NbDialogService,
  ) {
    this.role = this.cms.getuserData().Role

    var coin = localStorage.getItem('coin')
    if (coin) {
      this.selectCoinData = JSON.parse(coin)
      let coinis = JSON.parse(coin)
      this.getDefaultData()
      // this.getBalanceByCoin(this.selectCoinData);
      // this.dollorvolume = 0;
      // this.payload.volume = "0";

      console.log('coin data;', this.selectCoinData.coinName)
    }
    this.getCoinmarketValue()
  }

  ngOnInit(): void {
    this.cms.getQrCode(URL.GET_QR_CODE).subscribe((data) => {
      let result: any = data
      this.qrCode = result.Data.imagedata
    })
  }

  LatestPrices = []
  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, {
      context: 'this is some additional data passed to dialog',
    })
  }
  transferToMain(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, {})
  }
  closeTransfer(ref) {
    ref.close()
    this.cms
      .verifSecretCode(URL.VERIF_2FA, {
        otp: Number(this.secretCode),
      })
      .subscribe((data) => {
        let result: any = data
        if (result.Status == 400) {
          this.toastrService.warning(result.message, 'Error')
        } else {
          this.transferToMainWallet()
        }
        this.secretCode = ''
        console.log(result)
      })
  }
  getCoinmarketValue() {
    this.cms.commonGetCall(URL.coinMarket).subscribe((x: any) => {
      if (x.Status == 200) {
        var resp = x.Data
        resp.forEach((x) => {
          if (x.name == 'Bitcoin') {
            this.BtcMarketPrice = x.price
          }
          if (x.name == 'Dash') {
            this.DashMarketPrice = x.price
          }
          if (x.name == 'Ethereum') {
            this.EthMarketPrice = x.price
          }
          if (x.name == 'VegaWallet Token') {
            this.VgwMarketPrice = x.price
          }
        })

        this.LatestPrices = resp
      }
    })
  }

  dolorentered(val) {
    if (val >= 10) {
      // if(val){
      this.error[1] = ''
      this.tempval = val

      // if (this.selectCoinData.coinName == 'DASH') {
      //   this.payload.volume = (val / this.DashMarketPrice).toString()
      //   this.fee = ((val / this.DashMarketPrice) * 0.02).toFixed(8)

      //   this.transferamount = (val / this.DashMarketPrice - this.fee).toFixed(8)
      // }
      // if (this.selectCoinData.coinName == 'ETH') {
      //   this.payload.volume = (val / this.EthMarketPrice).toString()
      //   this.fee = ((val / this.EthMarketPrice) * 0.02).toFixed(8)
      //   this.transferamount = (val / this.EthMarketPrice - this.fee).toFixed(8)
      // }
      // if (this.selectCoinData.coinName == 'BTC') {
      //   this.payload.volume = (val / this.BtcMarketPrice).toString()
      //   this.fee = ((val / this.BtcMarketPrice) * 0.02).toFixed(8)
      //   this.transferamount = (val / this.BtcMarketPrice - this.fee).toFixed(8)
      // }
      // if (this.selectCoinData.coinName == 'VGW') {
      //   this.payload.volume = (val / this.VgwMarketPrice).toString()
      //   this.fee = ((val / this.VgwMarketPrice) * 0.02).toFixed(8)
      //   this.transferamount = (val / this.VgwMarketPrice - this.fee).toFixed(8)
      // }
      /////////////Changing the Fixed
      if (this.selectCoinData.coinName == 'DASH') {
        this.payload.volume = (val / this.DashMarketPrice).toString()
        this.coinVolume = Number(this.payload.volume)
        this.fee = ((val / this.DashMarketPrice) * 0.02).toString()

        this.transferamount = (val / this.DashMarketPrice - this.fee).toString()
      }
      if (this.selectCoinData.coinName == 'ETH') {
        this.payload.volume = (val / this.EthMarketPrice).toString()
        this.coinVolume = Number(this.payload.volume)
        this.fee = ((val / this.EthMarketPrice) * 0.02).toString()
        this.transferamount = (val / this.EthMarketPrice - this.fee).toString()
      }
      if (this.selectCoinData.coinName == 'BTC') {
        this.payload.volume = (val / this.BtcMarketPrice).toString()
        this.coinVolume = Number(this.payload.volume)
        this.fee = ((val / this.BtcMarketPrice) * 0.02).toString()
        this.transferamount = (val / this.BtcMarketPrice - this.fee).toString()
      }
      if (this.selectCoinData.coinName == 'VGW') {
        this.payload.volume = (val / this.VgwMarketPrice).toString()
        this.coinVolume = Number(this.payload.volume)
        this.fee = ((val / this.VgwMarketPrice) * 0.02).toString()
        this.transferamount = (val / this.VgwMarketPrice - this.fee).toString()
      }

      console.log('this.payload.volume', this.payload.volume)
    } else {
      this.error[1] = 'please enter minimum  Withdraw $10'
      this.payload.volume = ''
      this.tempval = val
      this.coinVolume = 0
    }
    ////////////
  }
  coinToDollar(val) {
    if (val >0) {
      // if(val){
      this.error[1] = ''
      this.tempval = val

      // if (this.selectCoinData.coinName == 'DASH') {
      //   this.payload.volume = (val / this.DashMarketPrice).toString()
      //   this.fee = ((val / this.DashMarketPrice) * 0.02).toFixed(8)

      //   this.transferamount = (val / this.DashMarketPrice - this.fee).toFixed(8)
      // }
      // if (this.selectCoinData.coinName == 'ETH') {
      //   this.payload.volume = (val / this.EthMarketPrice).toString()
      //   this.fee = ((val / this.EthMarketPrice) * 0.02).toFixed(8)
      //   this.transferamount = (val / this.EthMarketPrice - this.fee).toFixed(8)
      // }
      // if (this.selectCoinData.coinName == 'BTC') {
      //   this.payload.volume = (val / this.BtcMarketPrice).toString()
      //   this.fee = ((val / this.BtcMarketPrice) * 0.02).toFixed(8)
      //   this.transferamount = (val / this.BtcMarketPrice - this.fee).toFixed(8)
      // }
      // if (this.selectCoinData.coinName == 'VGW') {
      //   this.payload.volume = (val / this.VgwMarketPrice).toString()
      //   this.fee = ((val / this.VgwMarketPrice) * 0.02).toFixed(8)
      //   this.transferamount = (val / this.VgwMarketPrice - this.fee).toFixed(8)
      // }
      /////////////Changing the Fixed
      if (this.selectCoinData.coinName == 'DASH') {
        this.payload.volume = (val * this.DashMarketPrice).toString()
        this.dollorvolume = Number(this.payload.volume)
        this.fee = (val * this.DashMarketPrice * 0.02).toString()

        this.transferamount = (val * this.DashMarketPrice - this.fee).toString()
      }
      if (this.selectCoinData.coinName == 'ETH') {
        this.payload.volume = (val * this.EthMarketPrice).toString()
        this.dollorvolume = Number(this.payload.volume)
        this.fee = (val * this.EthMarketPrice * 0.02).toString()
        this.transferamount = (val * this.EthMarketPrice - this.fee).toString()
      }
      if (this.selectCoinData.coinName == 'BTC') {
        this.payload.volume = (val * this.BtcMarketPrice).toString()
        this.dollorvolume = Number(this.payload.volume)
        this.fee = (val * this.BtcMarketPrice * 0.02).toString()
        this.transferamount = (val * this.BtcMarketPrice - this.fee).toString()
      }
      if (this.selectCoinData.coinName == 'VGW') {
        this.payload.volume = (val * this.VgwMarketPrice).toString()
        this.dollorvolume = Number(this.payload.volume)
        this.fee = (val * this.VgwMarketPrice * 0.02).toString()
        this.transferamount = (val * this.VgwMarketPrice - this.fee).toString()
      }

      console.log('this.payload.volume', this.payload.volume)
    } else {
      this.error[1] = 'please enter minimum  Withdraw $10'
      this.payload.volume = ''
      this.tempval = val
      this.coinVolume = 0
    }
    ////////////
  }
  getDefaultData() {
    this.userData = this.cms.getuserData()
    this.cms.getSelectCoin().subscribe((data: any) => {
      console.log('Selection Coin', data)
      this.selectCoinData = data
      this.getBalanceByCoin()
      this.payload.volume = ''
      this.payload.TransferAddress = ''
      this.payload.Comment = ''
      this.dollorvolume = 0
      this.coinVolume = 0
      // this.dolorentered(this.tempval)
    })

    var coin = localStorage.getItem('coin')
    if (coin) {
      this.selectCoinData = JSON.parse(coin)

      this.getBalanceByCoin()

      // console.log("coin data;",coin)
    }
  }

  currentCoinPrice = 0

  getBalanceByCoin() {
    this.cms
      .commonGetCall(URL.getBalance + '/' + this.selectCoinData.coinId)
      .subscribe((resp: any) => {
        if (resp.Status == 200) {
          // console.log("Balance test balance", resp);
          this.CoinBalance = resp.Data.balance
          this.LatestPrices.forEach((x) => {
            if (
              x.symbol.toLocaleLowerCase() ==
              this.selectCoinData.coinName.toLocaleLowerCase()
            ) {
              this.selectCoinData.Price = x.price
              let amount = this.CoinBalance * x.price
              this.currentCoinPrice = Number(amount.toFixed(2))
            }
          })
        } else {
          //  alert(resp.message)
          this.toastrService.warning(resp.message, 'Error')
        }
      })
  }

  error = []
  transferWallet(dialog) {
    let code = localStorage.getItem('twoFactor')
    if (code != '') {
      this.transferToMain(dialog)
    } else {
      this.transferToMainWallet()
    }
  }

  transfertootherWallet() {
    this.error = []

    if (!this.payload.TransferAddress) {
      this.error[0] = 'Withdraw Address is required'
    }
    if (!this.dollorvolume) {
      this.error[1] = 'Withdraw volume  is required'
    }
    if (Number(this.payload.volume) > this.CoinBalance) {
      this.error[2] = 'Enter volume greater than your wallet'
    }
    if (!Number(this.payload.volume)) {
      this.error[1] = 'please enter minimum Withdraw $10'
    }

    if (this.error.length != 0) {
      return null
    }
    this.payload.coinId = this.selectCoinData.coinId
    this.cms
      .commonPostCall(URL.TransferToOtherWallet, this.payload)
      .subscribe((resp: any) => {
        if (resp.Status == 200) {
          this.toastrService.success(resp.message, 'Success')
          this.getBalanceByCoin()
          this.router.navigate(['/dashboard'])
        } else {
          this.toastrService.warning(resp.message, 'Error')
        }
      })
  }
  showTransferToOtherWalletDialog(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, {})
  }
  transferSecondaryWallet(dialog) {
    let code = localStorage.getItem('twoFactor')
    if (code) {
      this.showTransferToOtherWalletDialog(dialog)
    } else {
      this.transfertootherWallet()
    }
  }
  confirmTransfer(ref) {
    this.cms
      .verifSecretCode(URL.VERIF_2FA, {
        otp: Number(this.secretCode),
      })
      .subscribe((data) => {
        let result: any = data
        if (result.Status == 400) {
          this.toastrService.warning(result.message, 'Error')
        } else {
          this.transfertootherWallet()
        }
        this.secretCode = ''
        console.log(result)
      })

    ref.close()
  }
  transferToMainWallet() {
    this.error = []

    if (!this.CoinBalance) {
      this.error[3] = "your wallet volume is '0'"

      return null
    }

    var obj = {
      coinId: this.selectCoinData.coinId,
    }
    this.cms
      .commonPostCall(URL.TransferTomainWallet, obj)
      .subscribe((resp: any) => {
        if (resp.Status == 200) {
          // alert(resp.message)
          this.toastrService.success(resp.message, 'Success')
          this.getBalanceByCoin()
        } else {
          this.toastrService.warning(resp.message, 'Error')
        }
      })
  }

  ngOnDestroy() {
    this.alive = false
  }
  navigation(url) {
    this.router.navigateByUrl(url)
  }
  disable2FA(ref) {
    this.cms.commonGetCall(URL.DISABLE_2FA).subscribe((data) => {
      let result: any = data
      console.log(result)
      ref.close()
      this.toastrService.success('2FA has been succefully disabled', 'Success')
      localStorage.removeItem('twoFactor')
    })
  }
}
