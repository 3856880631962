import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbThemeService,
  NbToastrService,
  NbDialogService,
} from "@nebular/theme";
import { Subscription } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { SolarData } from "../../@core/data/solar";
import { CommonServiceService } from "../../common-service.service";
import { URL } from "../../model/url";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import { Router, ActivatedRoute } from "@angular/router";
import { ClipboardService } from "ngx-clipboard";
import { NewsmodalComponent } from "./newsmodal/newsmodal.component";
import { NewsService } from "../layout/news.service";
import { NewsitemService } from "./newsitem.service";
import 'rxjs/add/operator/toPromise';
interface CardSettings {
  title: string;
  iconClass: string;
  type: string;
}

@Component({
  selector: "ngx-dashboard",
  styleUrls: ["./dashboard.component.scss"],
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent implements OnInit {
  private alive = true;
  solarValue: number;
  lightCard: CardSettings = {
    title: "Light",
    iconClass: "nb-lightbulb",
    type: "primary",
  };
  rollerShadesCard: CardSettings = {
    title: "Roller Shades",
    iconClass: "nb-roller-shades",
    type: "success",
  };
  wirelessAudioCard: CardSettings = {
    title: "Wireless Audio",
    iconClass: "nb-audio",
    type: "info",
  };
  coffeeMakerCard: CardSettings = {
    title: "Coffee Maker",
    iconClass: "nb-coffee-maker",
    type: "warning",
  };

  statusCards: string;

  check_Transction = false;

  commonStatusCardsSet: CardSettings[] = [
    this.lightCard,
    this.rollerShadesCard,
    this.wirelessAudioCard,
    this.coffeeMakerCard,
  ];

  statusCardsByThemes: {
    default: CardSettings[];
    cosmic: CardSettings[];
    corporate: CardSettings[];
    dark: CardSettings[];
  } = {
      default: this.commonStatusCardsSet,
      cosmic: this.commonStatusCardsSet,
      corporate: [
        {
          ...this.lightCard,
          type: "warning",
        },
        {
          ...this.rollerShadesCard,
          type: "primary",
        },
        {
          ...this.wirelessAudioCard,
          type: "danger",
        },
        {
          ...this.coffeeMakerCard,
          type: "info",
        },
      ],
      dark: this.commonStatusCardsSet,
    };
  CoinBalance: any;
  userData: any;
  selectCoinData = {
    isMainAdmin: false,
    coinId: null,
    coinName: "",
    fullName: "",
    toolTip: "",
    isActive: "",
    environment: "",
    displayName: "",
    endPoint: "",
    icon: "",
  };

  EnterData = {
    note: "",
    volume: 0,
    amount: 0,
    currentPrice: 0,
  };

  token: any;
  valueChanges: any;
  constructor(
    private themeService: NbThemeService,
    private router: Router,
    private toastrService: NbToastrService,
    private _clipboardService: ClipboardService,
    private solarService: SolarData,
    public cms: CommonServiceService,
    private dialogService: NbDialogService,
    private newsServ: NewsitemService
  ) {
    // this.getCurrentvalues();

    this.themeService
      .getJsTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe((theme) => {
        this.statusCards = this.statusCardsByThemes[theme.name];
      });

    this.solarService
      .getSolarData()
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        this.solarValue = data;
      });
  }
  viewWithDraw = false;

  isMainAdmin = false;

  ngOnInit() {

    this.getAllBalances();

    this.userData = this.cms.getuserData();
    this.token = this.userData.PublicKey;
    var coin = localStorage.getItem("coin");

    if (this.userData.isMainAdmin == true) {
      this.isMainAdmin = true;
    }

    if (coin) {
      console.log("if coin issssss", coin);

      this.selectCoinData = JSON.parse(coin);
      // this.getData(this.selectCoinData);
      this.check_Transction = false;
    }
    if (this.userData.Role != 0 && this.userData.Role != 6) {
      this.viewWithDraw = true;
    }
    this.cms.getSelectCoin().toPromise().then((data) => {
      if (data.coinId) {
        this.selectCoinData = data;
        this.currency = data;
        this.getBalanceByCoin(data);
        this.check_Transction = false;
        this.getAddressByCoinId(data);
      }
    })
    .catch(()=>{
      // alert('hi')
      this.getData(this.selectCoinData);
    })

    // this.cms.getSelectCoin().subscribe((data: any) => {
    //       if (data.coinId) {
    //         this.selectCoinData = data;
    //         this.currency = data;
    //         this.getBalanceByCoin(data);
    //         this.check_Transction = false;
    //         this.getAddressByCoinId(data);
    //       }
    //     });


    this.getTwitterTreands();
this.getUserHistory();
this.cms.choosedCoinSelect();
  }

getData(data) {
  this.getBalanceByCoin(data);
  this.getAddressByCoinId(data);
}
AllBalances = []
balancesLoader = true;
getAllBalances() {
  this.cms.commonGetCall(URL.getChildTotalBalance).subscribe(
    (x: any) => {
      this.balancesLoader = false;
      console.log("all balancessss", x);
      this.AllBalances = x.Data
    },
    (err) => {
      this.balancesLoader = false;
      console.log(err);
    }
  );
}
totalbalance: any;
// getTotalBalance(){
//   this.cms.commonGetCall(URL.getChildTotalBalance).subscribe(
//     (x: any) => {
//       console.log("total balane balancessss",x);
//       // this.AllBalances = x.Data
//     },
//     (err) => {
//       console.log(err);
//     }
//   );

// }


getBalanceByCoin(obj) {
  console.log("Coin Obj", obj);
  // this.cms.commonGetCall(URL.getTotalBalance + "/" + obj.coinId)
  this.cms
    .commonGetCallOtherToken(
      URL.getTotalBalance + "/" + obj.coinId,
      this.token
    )
    .subscribe((resp: any) => {
      if (resp.Status == 200) {
        this.CoinBalance = resp.Data.balance;
        console.log("My Coin Balance", this.CoinBalance);
        this.getCurrentMarketPrice();
      }
      if (resp.Status == 400) {
        //  alert('hi')
        this.CoinBalance = 0
      } else {
      }
    });
}
CurrentPrice = 0;
CurrentValue = 0;

getCurrentMarketPrice() {
  this.CurrentPrice = 0;
  this.cms.commonGetCall(URL.coinMarket).subscribe(
    (x: any) => {
      var prices = x.Data.find(
        (y) => y.symbol == this.selectCoinData.coinName
      );
      this.CurrentPrice = prices.price;
      this.currency.CurrentPrice = prices.price;
    },
    (err) => {
      console.log(err);
    }
  );
}

precision(num, len) {
  if (num) {
    var num = num.toString();
    var s = num.split(".");

    if (s.length == 2) {
      if (s[1].length > len) {
        return parseFloat(s[0] + "." + s[1].slice(0, len));
      } else {
        return parseFloat(num);
      }
    } else {
      return parseFloat(num);
    }
  } else {
    return 0;
  }
}


twitterList = [];
getTwitterTreands() {
  this.cms.commonGetCall(URL.CRYPTO_NEWS).subscribe((res: any) => {
    console.log("Text Data:", res);

    this.twitterList = [];
    this.twitterList = res;

  });
}
history = {
  LHID: null,
  Network: "",
  City: "",
  Country: "",
  CountryCode: "",
  Latitude: null,
  Longitude: null,
  NetworkOrganization: "",
  IPAddress: "",
  StateCode: "",
  State: "",
  TimeZone: "",
  ZIP: "",
  DeviceType: "",
  CreatedDate: "",
  IsActive: null,
  ROLE: null,
  CmID: null,
  Count: null,
};

getUserHistory() {
  let url = URL.getUserLoginHistory;
  let payload = {
    CmID: this.userData.CmID,
    page: 0,
  };
  this.cms.commonPostCall(url, payload).subscribe((res: any) => {
    console.log("the login history", res);
    if (res.Status == 200) {
      if (res.Data.length != 0) {
        this.history = res.Data[0];

        // Create map instance
        var chart = am4core.create("chartdiv", am4maps.MapChart);

        // Set map definition
        chart.geodata = am4geodata_worldLow;

        // Set projection
        chart.projection = new am4maps.projections.Miller();

        // Create map polygon series
        var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

        // Make map load polygon (like country names) data from GeoJSON
        polygonSeries.useGeodata = true;

        // Configure series
        var polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name}";
        //    polygonTemplate.fill = am4core.color("#74B266");

        // Create hover state and set alternative fill color
        //  var hs = polygonTemplate.states.create("hover");
        //  hs.properties.fill = am4core.color("#367B25");

        // Remove Antarctica
        // polygonSeries.exclude = ["AQ"];

        // Add some data
        polygonSeries.data = [
          {
            id: this.history.CountryCode,
            name: this.history.Country,
            value: 100,
            fill: am4core.color("#56e39c"),
          },
        ];

        // Bind "fill" property to "fill" key in data
        polygonTemplate.propertyFields.fill = "fill";

        // Create image series
        var imageSeries = chart.series.push(new am4maps.MapImageSeries());

        // Create a circle image in image series template so it gets replicated to all new images
        var imageSeriesTemplate = imageSeries.mapImages.template;
        var circle = imageSeriesTemplate.createChild(am4core.Circle);
        circle.radius = 4;
        //  circle.fill = am4core.color("#ffffff");
        // circle.stroke = am4core.color("#ffffff");
        circle.strokeWidth = 2;
        circle.nonScaling = true;
        circle.tooltipText = "{title}";

        // let map = am4core.create("chartdiv", am4maps.MapChart);
        // map.geodata = am4geodata_worldLow;
        // let polygonSeries = new am4maps.MapPolygonSeries();
        // polygonSeries.useGeodata = true;
        // polygonSeries.data = [{
        //   "id": "US",
        //   "name": "United States",
        //   "value": 100,
        //   "fill": am4core.color("#F05C5C")
        // }, {
        //   "id": "FR",
        //   "name": "France",
        //   "value": 50,
        //   "fill": am4core.color("#5C5CFF")
        // }];
        // map.series.push(polygonSeries);

        // // Configure series
        // let polygonTemplate = polygonSeries.mapPolygons.template;
        // polygonTemplate.tooltipText = "{name}";
        // polygonTemplate.fill = am4core.color("#74B266");

        // // Create hover state and set alternative fill color
        // let hs = polygonTemplate.states.create("hover");
        // hs.properties.fill = am4core.color("#367B25");
      }
    }
  });
}

navigation(url) {
  this.router.navigateByUrl(url);
}
openExpoler() {
  if (this.selectCoinData.coinId == 1 || this.selectCoinData.coinId == 3) {
    window.open("https://sochain.com/");
  } else if (
    this.selectCoinData.coinId == 2 ||
    this.selectCoinData.coinId == 4
  ) {
    window.open("https://etherscan.io/");
  }
}
//

createNewAddress() {
  this.getAddressByCoinId({ coinId: this.cms.getSelectCoinId });
}

getAddressData: any;

currency = {
  coinId: null,
  coinName: "",
  fullName: "",
  toolTip: "",
  isActive: "",
  environment: "",
  displayName: "",
  endPoint: "",
  icon: "",
  CurrentPrice: null,
};
currentMarketValue = "";

getAddressByCoinId(obj) {
  let payload = {
    CoinID: obj.coinId,
  };
  this.cms.commonPostCall(URL.getAddress, payload).subscribe((resp: any) => {
    if (resp.Status == 200) {
      console.log("getAddresssssss", resp);
      this.getAddressData = resp.Data;
      this.getCurrentMarketPrice();
    } else {
    }
  });
}

getCurrentMarketPrice1() {
  this.cms.commonGetCall(URL.coinMarket).subscribe(
    (x: any) => {
      var prices = x.Data.find((y) => y.symbol == this.currency.coinName);
      this.currency.CurrentPrice = prices.price;
      console.log("Market Value prices:", prices);
    },
    (err) => {
      console.log(err);
    }
  );
}

copy(text: string) {
  this._clipboardService.copyFromContent(text);
  this.toastrService.success("Address successfully copied.", "Success");
}
open(item) {
  this.newsServ.setNewsItem(item);
  this.dialogService.open(NewsmodalComponent);
}


}
