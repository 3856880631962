import { Component, OnDestroy, OnInit } from '@angular/core'
import { NbThemeService, NbToastrService } from '@nebular/theme'
import { takeWhile } from 'rxjs/operators'
import { SolarData } from '../../@core/data/solar'
import { CommonServiceService } from '../../common-service.service'
import { URL } from '../../model/url'
import { ClipboardService } from 'ngx-clipboard'
import { ToasterConfig } from 'angular2-toaster'
import 'style-loader!angular2-toaster/toaster.css'
import { Router, ActivatedRoute } from '@angular/router'

interface CardSettings {
  title: string
  iconClass: string
  type: string
}

@Component({
  selector: 'ngx-invoice',
  styleUrls: ['./create-invoice.component.scss'],
  templateUrl: './create-invoice.component.html',
})
export class CreateInvoiceComponent implements OnDestroy, OnInit {
  private alive = true
  config: ToasterConfig

  constructor(
    private themeService: NbThemeService,
    private router: Router,
    private solarService: SolarData,
    private toastrService: NbToastrService,
    private _clipboardService: ClipboardService,
    public cms: CommonServiceService,
  ) {}

  address = {
    coinId: null,
    coinName: '',
    environment: '',
    coinFullName: '',
    cmID: null,
    address: '',
    addressID: null,
  }
  copy(text: string) {
    this._clipboardService.copyFromContent(text)
    this.toastrService.success('Address successfully copied.', 'Success')
  }

  ngOnDestroy() {
    if (this.apiloader) {
      clearInterval(this.apiloader)
    }

    let myurl = URL.login
    this.alive = false
  }
  userData: any

  selectCoinData = {
    coinId: null,
    coinName: '',
    fullName: '',
    toolTip: '',
    isActive: '',
    environment: '',
    displayName: '',
    endPoint: '',
    icon: '',
  }

  ngOnInit() {
    this.userData = this.cms.getuserData()
    this.cms.getSelectCoin().subscribe((data: any) => {
      if (data.coinId) {
        this.selectCoinData = data
        this.getCurrentvalues(data)
      }
    })
    var coin = localStorage.getItem('coin')
    if (coin) {
      this.selectCoinData = JSON.parse(coin)

      // console.log("coin data;",coin)
    }

    this.getCurrentvalues(this.selectCoinData)
  }

  EnterData = {
    note: '',
    volume: 0,
    amount: 0,
    currentPrice: 0,
  }
  // onKeydown(event)
  // {
  //   this.EnterData.volume = this.EnterData.amount / this.EnterData.currentPrice
  // }

  apiloader
  error = []
  generateAddress() {
    this.error = []
    if (!this.EnterData.amount) {
      this.error[0] = 'Amount is required'
    }

    if (this.error.length != 0) {
      return null
    }

    var payload = {
      CoinID: Number(this.selectCoinData.coinId),
      note: this.EnterData.note,
      volume: this.EnterData.amount / this.EnterData.currentPrice,
      amount: this.EnterData.amount,
    }
    this.EnterData.volume = payload.volume

    this.cms.commonPostCall(URL.getAddress, payload).subscribe((resp: any) => {
      if (resp.Status == 200) {
        this.address = resp.Data
        // console.log("getAddresssssss----", resp);
        this.toastrService.success(resp.message, 'Success')
        var self = this
        self.getTransactionsByCoinId()
        this.apiloader = setInterval(function () {
          self.getTransactionsByCoinId()
        }, 10000)
      } else {
      }
    })

    // console.log(payload)
  }
  public precision(num, len) {
    if (num) {
      var num = num.toString()
      var s = num.split('.')

      if (s.length == 2) {
        if (s[1].length > len) {
          return parseFloat(s[0] + '.' + s[1].slice(0, len))
        } else {
          return parseFloat(num)
        }
      } else {
        return parseFloat(num)
      }
    } else {
      return parseFloat(num)
    }
  }
  getCurrentvalues(selectCoinData) {
    this.cms.commonGetCall(URL.coinMarket).subscribe((resp: any) => {
      if (resp.Status == 200) {
        var currentCoin = resp.Data.find(
          (x) => x.symbol == selectCoinData.coinName,
        )
        console.log('response--resp:', currentCoin)
        this.EnterData.currentPrice = currentCoin.price
      }
    })
  }
  count = 0
  countInsert = true
  transctionData = {
    blockNumber: '',
    timeStamp: '',
    hash: '',
    nonce: '',
    blockHash: '',
    transactionIndex: '',
    from: '',
    to: '',
    value: '',
    gas: '',
    gasPrice: '',
    isError: '',
    txreceipt_status: '',
    input: '',
    contractAddress: '',
    cumulativeGasUsed: '',
    gasUsed: '',
    confirmations: '',
    TransferAmount: null,
    address: '',
    toAddress: '',
    category: '',
    amount: null,
    txid: '',
    time: '',
    timereceived: '',
  }
  getAllTransactions = []
  getTransactionsByCoinId() {
    this.cms
      .commonGetCallNoLoader(
        URL.getTransaction + '/' + this.selectCoinData.coinId,
      )
      .subscribe((resp: any) => {
        if (resp.Status == 200) {
          console.log('create invoice', resp.Data)
          this.getAllTransactions = resp.Data
          if (this.countInsert) {
            this.countInsert = false
            this.count = this.getAllTransactions.length
          } else {
            if (this.count != this.getAllTransactions.length) {
              this.transctionData = this.getAllTransactions[0]
            }
          }
          this.sorting()
        } else {
        }
      })
  }

  sorting() {
    this.getAllTransactions.sort(function (a, b) {
      return b.timereceived - a.timereceived
      // return <any>new Date(b.time) - <any>new Date(a.time);
    })
  }

  openTransction(id, type, coinname) {
    //console.log("openTransction:",id,type)

    // this.cms.navigateTransction(id, type, this.selectCoinData.coinName)
    this.cms.navigateTransction(id, type, coinname)
  }
  navigation() {
    this.router.navigateByUrl('/transactions')
  }
}
