import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { CoreModule } from "./@core/core.module";
import { ThemeModule } from "./@theme/theme.module";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { LoginModules } from "../app/pages/login/login.module";
import { SignupModules } from "../app/pages/signup/signup.module";
import { GrandadminModules } from "../app/pages/grandadmin/grandadmin.module";
import { PagesModule } from "../app/pages/pages.module";
import { CommonServiceService } from "./common-service.service";
import { ContactModule } from "./pages/contact/contact.module";
import { editprofileModule } from "./pages/editprofile/editprofile.module";
import { FormsModule } from "@angular/forms";
import { SharemoduleModule } from "./share/sharemodule/sharemodule.module";

import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from "@nebular/theme";


@NgModule({
  declarations: [AppComponent],
  imports: [
    SharemoduleModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    LoginModules,
    SignupModules,
    ContactModule,
    PagesModule,
    editprofileModule,
    GrandadminModules,
    ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: "AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY",
    }),
    CoreModule.forRoot(),
    FormsModule
  ],
  bootstrap: [AppComponent],
  providers: [CommonServiceService],
})
export class AppModule {}
