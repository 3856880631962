import { NgModule } from '@angular/core';
import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule }   from '@angular/forms';
import {
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    NbTooltipModule
  } from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';
import { CreateInvoiceComponent } from './create-invoice.component';
import { QRCodeModule } from 'angular2-qrcode';
import { ClipboardModule } from 'ngx-clipboard';
import { SharemoduleModule } from "../../share/sharemodule/sharemodule.module" 
// import { EditorsRoutingModule, routedComponents } from './editors-routing.module';

@NgModule({
  imports: [
    NbCardModule,
    ThemeModule,
    SharemoduleModule,
    // EditorsRoutingModule,
    NbActionsModule,
    NbButtonModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    CKEditorModule,
    FormsModule,
    QRCodeModule,
    ClipboardModule,
    NbTooltipModule
  ],
  declarations: [
    CreateInvoiceComponent
    // ...routedComponents,
  ],
})
export class CreateInvoiceModule { }
