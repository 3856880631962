import { Component, OnDestroy, TemplateRef, OnInit, ɵConsole } from '@angular/core'
import { takeWhile } from 'rxjs/operators'
import { SolarData } from '../../@core/data/solar'
import { Router } from '@angular/router'
import { URL } from '../../model/url'
import { CommonServiceService } from '../../common-service.service'
import { ToasterConfig } from 'angular2-toaster'
import * as _ from 'lodash'
import {
  NbThemeService,
  NbToastrService,
  NbDialogService,
} from '@nebular/theme'

@Component({
  selector: 'ngx-login',
  styleUrls: ['./editprofile.component.scss'],
  templateUrl: './editprofile.component.html',
})
export class editprofileComponent implements OnDestroy, OnInit {
  private alive = true
  user = {
    firstname: '',
    lastname: '',
    companyname: '',
    address: '',
  }
  UserRole: Number
  qrCode: string = ''

  constructor(
    private themeService: NbThemeService,
    public cms: CommonServiceService,
    private solarService: SolarData,
    private toastrService: NbToastrService,
    private router: Router,
    private dialogService: NbDialogService,
  ) {
    // this.changePassword();
    var userData = this.cms.getuserData()
    this.UserRole = userData.Role

    if (userData.Profilepic) {
      this.cardImageBase64 = userData.Profilepic
    }
    if (userData.logo) {
      this.logoImageBase64 = userData.logo
    }

    this.user = {
      firstname: userData.Fname,
      lastname: userData.Lname,
      companyname: userData.CompanyName,
      address: userData.CompanyAddress,
      // userData:Profilepic
    }
  }
  ngOnInit(): void {
    this.cms.getQrCode(URL.GET_QR_CODE).subscribe((data) => {
      let result: any = data
      this.qrCode = result.Data.imagedata;
      console.log(this.qrCode);
    })
  }

  ngOnDestroy() {
    this.alive = false
  }

  error = []
  update() {
    this.error = []
    var { error } = this
    if (!this.user.firstname) {
      error[0] = 'First Name is required '
    }
    if (!this.user.lastname) {
      error[1] = 'Last Name is required '
    }

    if (!this.user.companyname) {
      error[3] = 'Company Name is required '
    }
    if (!this.user.address) {
      error[4] = 'Company Address is required '
    }
    if (this.error.length != 0) {
      return null
    }

    var payload = {
      Fname: this.user.firstname,
      Lname: this.user.lastname,
      CompanyAddress: this.user.companyname,
      CompanyName: this.user.address,
      Profilepic: this.cardImageBase64,
      logo: this.logoImageBase64,
      lang: '',
    }

    this.cms
      .commonPostCall(URL.UPDATE_PROFILE, payload)
      .subscribe((resp: any) => {
        if (resp.Status == 200) {
          this.toastrService.success(resp.message, 'Success')
          var userData = this.cms.getuserData()

          userData.Fname = payload.Fname
          userData.Lname = payload.Lname
          userData.CompanyName = payload.CompanyName
          userData.CompanyAddress = payload.CompanyAddress
          if (payload.Profilepic) {
            userData.Profilepic = payload.Profilepic
          }
          if (payload.logo) {
            userData.logo = payload.logo
          }

          var encryptData = this.cms.encryptData(userData)
          localStorage.setItem('vega', encryptData)

          setTimeout(function () {
            window.location.reload()
          }, 1500)

          // console.log("this.userData:",userData)
        } else {
          this.toastrService.warning(resp.message, 'Error')
        }
      })
  }
  imageError: string
  isImageSaved: boolean
  cardImageBase64 = ''
  logiError: string
  isLogoSaved: boolean
  logoImageBase64 = ''

  fileChangeEvent(fileInput: any) {
    this.imageError = null
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520
      const allowed_types = ['image/png', 'image/jpeg']
      const max_height = 15200
      const max_width = 25600

      if (fileInput.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb'

        return false
      }

      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.imageError = 'Only Images are allowed ( JPG | PNG )'
        return false
      }
      const reader = new FileReader()
      reader.onload = (e: any) => {
        const image = new Image()
        image.src = e.target.result
        image.onload = (rs) => {
          const img_height = rs.currentTarget['height']
          const img_width = rs.currentTarget['width']

          console.log(img_height, img_width)

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              'Maximum dimentions allowed ' +
              max_height +
              '*' +
              max_width +
              'px'
            return false
          } else {
            const imgBase64Path = e.target.result
            this.cardImageBase64 = imgBase64Path
            this.isImageSaved = true
            // this.previewImagePath = imgBase64Path;
          }
        }
      }

      reader.readAsDataURL(fileInput.target.files[0])
    }
  }
  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, {
      context: 'this is some additional data passed to dialog',
    })
  }
  filUplouadChange(fileInput: any) {
    this.imageError = null
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 512000;
      const allowed_types = ['image/png', 'image/jpeg']
      const max_height = 15200
      const max_width = 25600
     console.log( fileInput.target.files[0].size) ;
      if (fileInput.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1024 + 'Mb'
        this.toastrService.warning(this.imageError,'Error');
        return false
      }

      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.logiError = 'Only Images are allowed ( JPG | PNG )';
        this.toastrService.warning(this.logiError,'Error');
        return false
      }
      const reader = new FileReader()
      reader.onload = (e: any) => {
        const image = new Image()
        image.src = e.target.result
        image.onload = (rs) => {
          const img_height = rs.currentTarget['height']
          const img_width = rs.currentTarget['width']

          console.log(img_height, img_width)

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              'Maximum dimentions allowed are' +
              max_height +
              '*' +
              max_width +
              'px'
              this.toastrService.warning(this.imageError,'Error');
            return false
          } else {
            const imgBase64Path = e.target.result
            this.logoImageBase64 = imgBase64Path
            this.isLogoSaved = true
            // this.previewImagePath = imgBase64Path;
          }
        }
      }

      reader.readAsDataURL(fileInput.target.files[0])
    }
  }
  // changePassword() {
  //   let url = URL.updatePassword;
  //   let payload = {
  //     OldPassword: this.userDetails.OldPassword,
  //     Password:this.userDetails.Password,
  //     ConfirmPassword: this.userDetails.ConfirmPassword
  //   }
  //   if(this.userDetails.Password != this.userDetails.ConfirmPassword){
  //     alert('Password and Confirm Password should match');
  //   }
  //   else{
  //     this.cms.commonPostCall(url,payload).subscribe((res:any)=>{
  //       if(res.Status == 200){
  //         alert(res.message);
  //       }
  //       else{
  //         alert(res.message);
  //       }

  //     })
  //   }

  // }
}
