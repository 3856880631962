import { Component, OnInit } from '@angular/core'
import { CommonServiceService } from '../../common-service.service'
import { URL } from '../../model/url'
@Component({
  selector: 'ngx-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  coinList: any = []
  constructor(private cms: CommonServiceService) {}

  ngOnInit() {
    this.getCoinData()
  }

  getCoinData() {
    this.cms.loaderSet(true)
    this.cms.getCoinList().subscribe((data) => {
      let result: any = data

      this.coinList = result.Data.map((elm) => {
        console.log(elm)
        if (elm.IsActive == true) {
          return {
            id: elm.coinId,
            coinName: elm.coinName,
            displayName: elm.displayName,
            icon: elm.icon,
            isActive: elm.IsActive,
            status: 'Active',
          }
        } else {
          return {
            id: elm.coinId,
            coinName: elm.coinName,
            displayName: elm.displayName,
            icon: elm.icon,
            isActive: elm.IsActive,
            status: 'Inactive',
          }
        }
      })
      console.log('Coins', this.coinList)
      this.cms.loaderSet(false)
    })
  }
  checked = false

  toggle(ind) {
    let coin = this.coinList[ind]
    let payload = {
      coinid: coin.id,
      status: coin.isActive,
    }
    this.cms.changeCoinStatus(URL.ToggleCoin, payload).subscribe((data) => {
      let result: any = data
      console.log(result)
      this.getCoinData()
    })
    // if (this.coinList[ind].isActive == true) {
    //   this.coinList[ind].status = 'Active'
    // } else {
    //   this.coinList[ind].status = 'Inactive'
    // }
  }
}
