import { Component, OnDestroy, OnInit, NgZone } from '@angular/core'
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme'

import { UserData } from '../../../@core/data/users'
import { LayoutService } from '../../../@core/utils'
import { filter, map, takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { CommonServiceService } from '../../../common-service.service'
import { URL } from '../../../model/url'
import { Router } from '@angular/router'
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>()
  userPictureOnly: boolean = false
  user: any

  DashMarketPrice: 0
  EthMarketPrice: 0
  BtcMarketPrice: 0
  VgwMarketPrice: 0
  lightTheme: boolean = false
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ]

  currentTheme = 'default'
  users: { name: string; title: string }[] = [
    { name: 'Carla Espinosa', title: 'Nurse' },
    { name: 'Bob Kelso', title: 'Doctor of Medicine' },
    { name: 'Janitor', title: 'Janitor' },
    { name: 'Perry Cox', title: 'Doctor of Medicine' },
    { name: 'Ben Sullivan', title: 'Carpenter and photographer' },
  ]

  userMenu = [
    { title: 'Edit Profile', link: '/editprofile' },
    { title: 'Change Password', link: '/changepassword' },
    { title: 'Log out', link: '/logout' },
  ]
  logindata: any
  CoinsList: any
  selectedCoin: string
  userData: any;
  logo = '';
  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    public cms: CommonServiceService,
    public route: Router,
    public zone: NgZone,
    private breakpointService: NbMediaBreakpointsService,
  ) {
    let data = localStorage.getItem('LoginData')
    this.logindata = JSON.parse(data)
    // this.CoinsList=[{value:1,name:"DASH"}];
    // this.selectedCoin= 1;
    this.getCoinsList()
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme

    this.userData = this.cms.getuserData();
    this.logo = "../../../assets/images/vegapay-logo.png";
    // if(this.userData.logo)
    // {
    //   this.logo = this.userData.logo;
    // }
    // else
    // {
    //   this.logo = "../../../assets/images/vegapay-logo.png";
    // }
   //  console.log('username',this.userData);

    this.user = {
      name: this.userData.Fname + ' ' + this.userData.Lname,
      picture: this.userData.Profilepic,
    }
    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'usr'),
        map(({ item: { title } }) => title),
      )
      .subscribe((title) => console.log(title))

    // this.cms.choosedSet()
    // .subscribe((data:any)=>{
    //   if(this.coinChoosed.coinId)
    //   {

    //    this.cms.setSelectCoin(this.coinChoosed);
    //    this.cms.setCoinId(this.coinChoosed.coinId);
    //   }
    //   else{

    //   }

    // })

    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = users.nick);

    // const { xl } = this.breakpointService.getBreakpointsMap();
    // this.themeService.onMediaQueryChange()
    //   .pipe(
    //     map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    // this.themeService.onThemeChange()
    //   .pipe(
    //     map(({ name }) => name),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe(themeName => this.currentTheme = themeName);

    this.getCoinmarketValue()
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  coinChoosed: any

  changeCoin(value: any) {
  //  console.log(value)
    let selectedCoin = this.mainCoinList.find((x) => x.coinId == value)
    this.coinChoosed = selectedCoin

    // localStorage.setItem("coinnnnn", JSON.stringify(selectedCoin));
    localStorage.setItem('coin', JSON.stringify(selectedCoin))
    this.cms.setSelectCoin(selectedCoin)
    this.cms.setCoinId(this.selectedCoin)
  //  console.log('the heareddddd sected', selectedCoin)

    this.selectedCoin = value
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar')
    this.layoutService.changeLayoutSize()

    return false
  }

  navigateHome() {
    this.menuService.navigateHome()
    return false
  }
  mainCoinList = []

  getCoinsList() {
    this.zone.run(() => {
      this.cms.commonGetCall(URL.getcoinlist).subscribe((resp: any) => {
        if (resp.Status == 200) {
          // console.log("header data", resp);
          this.mainCoinList = resp.Data
          this.cms.setCoinList(this.mainCoinList)
          this.CoinsList = resp.Data.map((x) => {
            return { value: x.coinId, name: x.coinName }
          })
          var coin = localStorage.getItem('coin')
          if (coin) {
            let coindata = JSON.parse(coin)
            //  this.getAddressByCoinId(this.currency);
            let selectedCoin = this.CoinsList.find(
              (x) => x.value == coindata.coinId,
            )

            this.selectedCoin = selectedCoin.value
            this.changeCoin(this.selectedCoin)
            //this.cms.setCoinId(this.selectedCoin);
          //  console.log('selectedCoinselectedCoin', selectedCoin)
          } else {
            this.selectedCoin = this.CoinsList[0].value
            this.changeCoin(this.selectedCoin)
            // this.cms.setCoinId(this.selectedCoin);
            // console.log("selectedCoinselectedCoin",selectedCoin);
          }

          //  this.selectedCoin = this.CoinsList[0].value;
        }
      })
    })
  }

  getCoinmarketValue() {
    this.cms.commonGetCall(URL.coinMarket).subscribe((x: any) => {
      if (x.Status == 200) {
        var resp = x.Data
        resp.forEach((x) => {
          if (x.name == 'Bitcoin') {
            this.BtcMarketPrice = x.price.toFixed(2)
          }
          if (x.name == 'Dash') {
            this.DashMarketPrice = x.price.toFixed(2)
          }
          if (x.name == 'Ethereum') {
            this.EthMarketPrice = x.price.toFixed(2)
          }
          if (x.name == 'VegaWallet Token') {
            this.VgwMarketPrice = x.price.toFixed(2)
          }
        })
      }
    })
  }
  changeTheme() {
    if (this.lightTheme == true) {
      this.themeService.changeTheme('light')
    } else {
      this.themeService.changeTheme('dark')
    }
  }
  logout() {
    localStorage.clear()
    this.route.navigate(['/'])
  }
  changePassword() {
    this.route.navigate(['changepassword'])
  }
}
